import { api, http, getScope } from '../common/common';

function resource(path, actions = {}) {
    return Object.assign(
        {
            get: (id, params = {}, config = {}) => api.get(`${path}/${id}`, { params, ...config }),
            save: (obj, params = {}, config = {}) => api.post(path, obj, { params, ...config }),
            query: (params = {}, config = {}) => api.get(path, { params, ...config }),
            update: (id, obj) => api.put(`${path}/${id}`, obj),
            delete: (id) => api.delete(`${path}/${id}`),
        },
        actions
    );
}

//region Address
export const addressService = {
    queryPostcode: (params = {}) => api.get(`/addresses/postcode`, { params }),
};
//endregion

//region AWS
export const AwsService = {
    signUpload: (params = {}) => api.get(`/aws/sign`, { params }),
};
//endregion

//region Carrier
export const canvasService = resource('/canvas', {
    getCanvas: (id) => api.get(`/canvas/${id}`),
});
//endregion

//region Carrier
export const carrierService = resource('/carriers');
//endregion

//region Carrier
export const categoryService = resource('/categories', {
    getFeaturedProducts: (id, params = {}) => api.get(`/categories/${id}/featured`, { params }),
});
//endregion

//complaint
export const complaintsService = resource('/complaint', {
    getTypesComplaints: (params = {}) => api.get(`/complaint/type_complaint`, { params }),
    sendComplaint: (obj, params = {}) => api.post(`/complaint/create/`, obj, { params }),
});
//endcomplaint

//region Cart
export const cartService = resource('/carts', {
    getLatest: (params = {}) => api.get(`/carts/latest`, { params }),
    addToCart: (obj, params = {}) => http.post('/app/scripts/cart.php', obj, { params }),
    getProducts: (id, params = {}) => api.get(`/carts/${id}/products/`, { params }),
    updateProducts: (id, obj, params = {}) => api.post(`/carts/${id}/products/`, obj, { params }),
    addDiscount: (id, obj, params = {}) => api.post(`/carts/${id}/discounts/`, obj, { params }),
    removeDiscount: (cartId, discountId) => api.delete(`/carts/${cartId}/discounts/${discountId}`),
    addPrime: (id, params = {}) => api.post(`/carts/${id}/prime`, { params }),
    removePrime: (id, params = {}) => api.post(`/carts/${id}/prime`, { quantity: -1, ...params }),
    paymentMethods: (id, params = {}) => api.get(`/carts/${id}/payment_methods`, { params }),
    checkout: (id, obj, params = {}) => api.post(`/carts/${id}/checkout/`, obj, { params }),
    copy: (id, obj, params = {}) => api.post(`/carts/${id}/copy`, obj, { params }),
    updateForCustomer: (id, obj, params = {}) =>
        api.post(`/carts/${id}/for_customer`, obj, { params }),
    updateOrganization: (id, obj, params = {}) =>
        api.post(`/carts/${id}/organization`, obj, { params }),
    createCart: (obj, params = {}) => http.post('/create_cart.php', obj, { params }),
    changeCart: (obj, params = {}) => http.post('/change_cart.php', obj, { params }),
});
//endregion

//region Country
export const countryService = resource('/countries');

// TODO Corrigir
export const countryRegionService = {
    query: (params = {}, config = {}) =>
        api.get(`https://arcoiro.chicorei.com/country_region`, {
            params,
            ...config,
        }),
};

//endregion

//region Customer
export const customerService = resource('/customers', {
    getExchanges: (id, params = {}) => api.get(`/customers/${id}/exchanges`, { params }),
    getExchangeableOrders: (id, params = {}) =>
        api.get(`/customers/${id}/orders/exchangeable`, { params }),
    getDiscounts: (id, params = {}) => api.get(`/customers/${id}/discounts`, { params }),
    getPhotos: (id, params = {}) => api.get(`/customers/${id}/gallery`, { params }),
    savePhoto: (id, obj, params) => api.post(`/customers/${id}/gallery`, obj, { params }),
    getWishlist: (id, params = {}) => api.get(`/customers/${id}/wishlist`, { params }),
    addWishlistProduct: (id, obj) => api.post(`/customers/${id}/wishlist`, obj),
    deleteWishlistProduct: (customerId, wishlistPoductId) =>
        api.delete(`/customers/${customerId}/wishlist/${wishlistPoductId}`),
    postAvatar: (customerId, obj) => api.post(`/customers/${customerId}/avatar`, obj),
    deleteAvatar: (customerId) => api.delete(`/customers/${customerId}/avatar`),
    sendReferEmail: (customerId, obj) => api.post(`/customers/${customerId}/refer`, obj),
    getRepresentedCustomers: (customerId, params = {}) =>
        api.get(`/customers/${customerId}/represented`, { params }),
    newPartnership: (id, obj) => api.post(`/customers/${id}/new_partnership`, obj),
    urlValidation: (id, params = {}) => api.get(`/customers/${id}/url_valid`, { params }),
});
//endregion

//region Customer Address
export const getCustomerAddressService = (customerId) =>
    resource(`/customers/${customerId}/addresses`, {
        makeDefault: (id) => api.put(`/customers/${customerId}/addresses/${id}/default`),
    });
//endregion

//region Customer Order
export const getCustomerOrderService = (customerId) =>
    resource(`/customers/${customerId}/orders`, {
        getRepresented: (params = {}) =>
            api.get(`/customers/${customerId}/orders/represented`, { params }),
    });
//endregion

//region Discount
export const discountService = resource('/discounts', {
    find: (params = {}) => api.get(`/discounts/find`, { params }),
});

//region Description
export const fabricService = {
    getAllowedProductionWithoutStock: (params = {}) =>
        api.get(`/fabrics/allowed_production_without_stock`, { params }),
};
//endregion

//region Featured Discount
export const featuredDiscountService = resource('/featured_discounts', {
    sendEmail: (id, obj) => api.post(`/featured_discounts/${id}/send_email`, obj),
});
//endregion

//region Exchange
export const exchangeService = resource('/exchanges', {
    getCauses: () => api.get(`/exchanges/causes`),
    getCausesReasons: () => api.get(`/exchanges/causes_reasons`),
});
//endregion

//region Gallery
export const galleryService = resource('/gallery');
//endregion

//region Hashtag
export const hashtagService = resource('/hashtags');
//endregion

//region Home Collection
export const homeCollectionService = resource('/home/collections');
//endregion

//region Order
export const orderService = {
    getOrderTracking: (id, params = {}) => api.get(`/orders/${id}/tracking`, { params }),
};
//endregion

//region Payment Link
export const paymentLinkService = {
    process: (id, obj, params = {}) => api.post(`/payment_link/${id}/process`, obj, { params }),
};
//endregion

//region Partner
export const partnerService = resource('/partners', {
    countArts: (id, params = {}) => api.get(`/partners/${id}/arts/count`, { params }),
    sendContactEmail: (obj, params = {}) => api.post(`/partners/contact`, obj, { params }),
});
//endregion

//region Product
export const productService = resource('/products', {
    queryStock: (id, params = {}) => api.get(`/products/${id}/stock`, { params }),
    queryComments: (id, params = {}) => api.get(`/products/${id}/comments`, { params }),
    queryPhotos: (id, params = {}) => api.get(`/products/${id}/photos`, { params }),
    addComment: (id, obj, params = {}) => api.post(`/products/${id}/comments`, obj, { params }),
    requestReprint: (productId, productSizeId, obj, params) =>
        api.post(`/products/${productId}/reprint/${productSizeId}`, obj, {
            params,
        }),
    getFeatured: (params = {}) => api.get(`/products/featured`, { params }),
    getProduct: (id, params = {}) => api.get(`/products/${id}`, { params }),
    getProductImages: (id, params = {}) => api.get(`/products/${id}/images`, { params }),
    getCustomizableProduct: (id, params = {}) =>
        api.get(`/products/customizable_product/${id}`, { params }),
});

export const productSearchApi = {
    query: (params = {}, config = {}) =>
        http.get(`${getScope('API_LAMBDA_URL')}/products/v4/search`, {
            params,
            ...config,
        }),
};

//region Product Model
export const productModelService = resource('/product_model');
//endregion

// TODO Corrigir

//region Product Type
export const productTypeService = {
    get: (id, params = {}, config = {}) =>
        api.get(`https://arcoiro.chicorei.com/tipo_produto_lang/${id}`, {
            params,
            ...config,
        }),
};
//endregion

//region Product Type
export const organizationService = resource('/organizations', {
    getOrganizations: (params, config = {}) => api.get(`/organizations`, { params, ...config }),
});
//endregion

//region Search
export const searchService = {
    getResults: (params = {}, config = {}) => api.get('/api/search', { params, ...config }),
};
//endregion

//region Bank
export const bankService = resource('/bank');
//endregion

//region Store
export const storeService = resource('/store', {
    listProducts: (id, params, config = {}) =>
        api.get(`/store/${id}/products`, { params, ...config }),
    listCustomers: (id, params, config = {}) =>
        api.get(`/store/${id}/customers`, { params, ...config }),
    getSheet: (id, params = {}) =>
        api.get(`/store/${id}/customers/export`, { params, responseType: 'blob' }),
    updateActive: (idStore, idProduct, obj) =>
        api.post(`/store/${idStore}/product/${idProduct}/active`, obj),
    allowAuditConsent: (idStore, obj) => api.put(`/store/${idStore}/audit_consent`, obj),
    deleteProduct: (idStore, idProduct, obj) =>
        api.delete(`/store/${idStore}/product/${idProduct}`, obj),
    productArchive: (idStore, idProduct, obj) =>
        api.post(`/store/${idStore}/product/${idProduct}/archive`, obj),
    updateListable: (idStore, idProduct, obj) =>
        api.post(`/store/${idStore}/product/${idProduct}/list`, obj),
    saveOrUpdateBankAccount: (idStore, obj) => api.post(`/store/${idStore}/bank_account`, obj),
    getProduct: (idStore, idProduct, params, config = {}) =>
        api.get(`/store/${idStore}/product/${idProduct}/getProduct`, {
            params,
            ...config,
        }),
    getBaseProducts: (id, params, config = {}) =>
        api.get(`/store/${id}/get_customizable_products`, {
            params,
            ...config,
        }),
    saveProduct: (id, obj, params, config = {}) =>
        api.post(`/store/${id}/product/save/`, obj, { params, ...config }),
    updateProduct: (idStore, idProduct, obj, params, config = {}) =>
        api.put(`/store/${idStore}/product/${idProduct}/update`, obj, {
            params,
            ...config,
        }),

    uploadFileAbout: (id, obj, config = {}) =>
        api.post(`/store/${id}/upload_file_about`, obj, {
            ...config,
        }),
    createAbout: (id, obj, config = {}) =>
        api.post(`/store/${id}/createAbout`, obj, {
            ...config,
        }),
    updateAbout: (id, obj, config = {}) =>
        api.post(`/store/${id}/update_about`, obj, {
            ...config,
        }),

    uploadFile: (id, obj, config = {}) =>
        api.post(`/store/${id}/upload_file`, obj, {
            ...config,
        }),
    updateStore: (id, obj, params = {}, config = {}) =>
        api.put(`/store/${id}/update`, obj, {
            params,
            ...config,
        }),
    updatePublicity: (id, obj, params = {}, config = {}) =>
        api.put(`/store/${id}/update/publicity`, obj, {
            params,
            ...config,
        }),
    defrostStore: (id, obj = {}, params = {}, config = {}) =>
        api.post(`/store/${id}/defrost`, obj, {
            params,
            ...config,
        }),
    updateSocial: (id, obj, params = {}, config = {}) =>
        api.put(`/store/${id}/update/social`, obj, {
            params,
            ...config,
        }),
    getOrders: (idStore, params = {}, config = {}) =>
        api.get(`/store/${idStore}/sales`, { params, ...config }),
    getOrdersItems: (idStore, params = {}, config = {}) =>
        api.get(`/store/${idStore}/sales_items`, { params, ...config }),
    getStatistics: (idStore, params = {}, config = {}) =>
        api.get(`/store/${idStore}/statistics`, { params, ...config }),

    getBalance: (id, params = {}, config = {}) =>
        api.get(`/store/${id}/balance`, { params, ...config }),

    getTransfers: (id, params = {}, config = {}) =>
        api.get(`/store/${id}/transfer`, { params, ...config }),

    createTransfer: (id, obj, config = {}) =>
        api.post(`/store/${id}/transfer`, obj, {
            ...config,
        }),

    createNotification: (id, obj, config = {}) =>
        api.post(`/store/${id}/create_notification`, obj, {
            ...config,
        }),

    getNotification: (id, params = {}, config = {}) =>
        api.get(`/store/${id}/notification`, { params, ...config }),

    availableForPromotion: (id, params = {}, config = {}) =>
        api.get(`/store/${id}/products/availableForPromotion`, { params, ...config }),

    getCategories: (id, params = {}, config = {}) =>
        api.get(`/store/${id}/categories`, { params, ...config }),

    updateCategory: (idStore, idCategory, obj, params = {}, config = {}) =>
        api.put(`/store/${idStore}/category/${idCategory}`, obj, {
            params,
            ...config,
        }),

    saveCategory: (id, obj, params = {}, config = {}) =>
        api.post(`/store/${id}/category`, obj, { params, ...config }),

    getCategory: (idStore, idCategory, params = {}, config = {}) =>
        api.get(`/store/${idStore}/category/${idCategory}`, {
            params,
            ...config,
        }),
    getBoostedProducts: (id, params, config = {}) =>
        api.get(`/store/${id}/product_boost_list`, { params, ...config }),

    updateBoostedProducts: (id, params, config = {}) =>
        api.post(`/store/${id}/product_boost_list`, { params, ...config }),

    getOverallStatistics: (id, params, config = {}) =>
        api.get(`/store/${id}/statistics/overall`, { params, ...config }),

    getSalesStatistics: (id, params, config = {}) =>
        api.get(`/store/${id}/statistics/orders`, { params, ...config }),

    getProductPanelStatistics: (id, params, config = {}) =>
        api.get(`/store/${id}/statistics/products`, { params, ...config }),

    getOrdersStatistics: (id, params, config = {}) =>
        api.get(`/store/${id}/statistics/orders/list`, { params, ...config }),

    getProductsStatistics: (id, params, config = {}) =>
        api.get(`/store/${id}/statistics/products/list`, { params, ...config }),

    getOrderStatistics: (id, idOrder, params, config = {}) =>
        api.get(`/store/${id}/statistics/orders/${idOrder}`, { params, ...config }),

    getProductStatistics: (id, idProduct, params, config = {}) =>
        api.get(`/store/${id}/statistics/products/${idProduct}`, { params, ...config }),
});
//endregion

//region Product Image
export const storeProductImageService = {
    getCreatedImages: (idStore, idProduct, params = {}) =>
        api.get(`/store/${idStore}/product/${idProduct}/images`, { params }),
    saveProductImage: (idStore, idProduct, data) =>
        api.post(`/store/${idStore}/product/${idProduct}/image`, data),
    updateProudctImageDefault: (idStore, idProduct, imageDefaultId, data) =>
        api.put(`/store/${idStore}/product/${idProduct}/image/${imageDefaultId}/default`, data),
    update: (idStore, idProduct, idProductImage, data) =>
        api.put(`/store/${idStore}/product/${idProduct}/image/${idProductImage}`, data),
    delete: (idStore, idProduct, idProductImage) =>
        api.delete(`/store/${idStore}/product/${idProduct}/image/${idProductImage}`),
    sort: (idStore, idProduct, data) =>
        api.post(`/store/${idStore}/product/${idProduct}/image/sort`, data),
};
//endregion

//region Promotion
export const promotionService = resource('/promotion', {
    save: (id, obj, params, config = {}) =>
        api.post(`/store/${id}/promotion`, obj, { params, ...config }),

    getPromotions: (id, params, config = {}) =>
        api.get(`/store/${id}/promotion`, { params, ...config }),

    cancel: (idStore, idOferta) => api.post(`/store/${idStore}/promotion/${idOferta}/cancel`),

    changeDate: (idStore, idOferta, params = {}, config = {}) =>
        api.post(`/store/${idStore}/promotion/${idOferta}/changeDate`, { params, ...config }),

    find: (idStore, idOferta) => api.get(`/store/${idStore}/promotion/${idOferta}/find`),

    getOfertaProdutos: (idStore, idOferta, obj) =>
        api.get(`/store/${idStore}/promotion/${idOferta}/getOfertaProdutos`, obj),
});
//endregion

//region Discounts
export const storeDiscountService = resource('/discount', {
    find: (storeId, discountId, params, config = {}) =>
        api.get(`/store/${storeId}/discount/${discountId}`, { params, ...config }),
    create: (storeId, obj, params, config = {}) =>
        api.post(`/store/${storeId}/discount`, obj, { params, ...config }),
    update: (storeId, discountId, obj, params, config = {}) =>
        api.put(`/store/${storeId}/discount/${discountId}`, obj, { params, ...config }),
    getDiscounts: (storeId, params, config = {}) =>
        api.get(`/store/${storeId}/discount`, { params, ...config }),
    updateActive: (storeId, discountId, obj, params, config = {}) =>
        api.put(`/store/${storeId}/discount/${discountId}/active`, obj, { params, ...config }),
});
//endregion

//region Blog
export const postsBlogService = resource('/blog', {
    getPostsBlog: (params, config = {}) => api.get(`/blog_posts`, { params, ...config }),
});
//
