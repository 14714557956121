<template>
    <div>
        <div class="card-block d-flex flex-align-center cursor-pointer" @click="toggle">
            <span v-if="title" class="text-bold mr-auto">
                {{ title }}
            </span>
            <slot name="heading" />
            <span
                v-if="useFasIcon"
                class="fas fa-chevron-down transition-transform ml"
                :style="`transform: rotate(${open ? 180 : 0}deg)`"
            />
            <span
                v-else
                class="icon-cr icon-chevron-down transition-transform ml"
                :style="`transform: rotate(${open ? 180 : 0}deg)`"
            />
        </div>
        <div class="collapsible" :class="{ open }">
            <div class="card-block">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
    import CollapsibleGroupItem from '../collapsible-group-item/CollapsibleGroupItem';

    export default {
        name: 'CollapsibleCardBlock',

        extends: CollapsibleGroupItem,

        props: {
            title: String,
            titleClass: String,
            useFasIcon: {
                type: Boolean,
                default: true,
            },
        },
    };
</script>
